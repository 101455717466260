import { Fragment, useState } from "react";
import "./App.css";
import Header from "./components/header/Header";
import locales from "./context/locales.json";
import Daily from "./pages/daily/Daily";
import Recieption from "./pages/recieption/Recieption";
import Account from "./pages/account/Account";
import Customers from "./pages/customers/Customers";
import Login from "./pages/login/Login";
import Profile from "./pages/profile/Profile";
import Forms from "./Forms";

function App() {
  const [select, setSelect] = useState(locales.Daily);
  const [User, setUser] = useState(locales.Nessa);
  const [login, setLogin] = useState(false);
  const [Years, setYears] = useState(1403);
  const [Days, setDays] = useState(1);
  const [selectedDaily, setselectedDaily] = useState("FA");

  return (
    <Fragment>
      {!login ? (
        <Login User={User} setUser={setUser} setLogin={setLogin} />
      ) : (
        <Fragment>
          <Header select={select} setSelect={setSelect} User={User} />
          {select === locales.Daily ? (
            <Daily
              User={User}
              Years={Years}
              setYears={setYears}
              Days={Days}
              setDays={setDays}
              selectedDaily={selectedDaily}
              setselectedDaily={setselectedDaily}
            />
          ) : null}
          {select === locales.Recieption ? <Recieption /> : null}
          {select === locales.Account ? <Account /> : null}
          {select === locales.Customers ? <Customers /> : null}
          {select === locales.Profile ? (
            <Profile User={User} setLogin={setLogin} />
          ) : null}
        </Fragment>
      )}
      {/* <Forms /> */}
    </Fragment>
  );
}

export default App;
