import { Box } from "@mui/material";
import React, { useState } from "react";
import classess from "./Calander.module.css";
import NavigateNextRoundedIcon from "@mui/icons-material/NavigateNextRounded";

const Calander = ({
  Years,
  setYears,
  Days,
  setDays,
  selectedDaily,
  setselectedDaily,
}) => {
  return (
    <Box className={classess.Pluspage}>
      <Box className={classess.MonthYear}>
        <Box>
          <select
            value={selectedDaily}
            onChange={(event) => setselectedDaily(event.target.value)}
          >
            <option value="FA">FA</option>
            <option value="OR">OR</option>
            <option value="KH">KH</option>
            <option value="TI">TI</option>
            <option value="MO">MO</option>
            <option value="SH">SH</option>
            <option value="ME">ME</option>
            <option value="AB">AB</option>
            <option value="AZ">AZ</option>
            <option value="DE">DE</option>
            <option value="BA">BA</option>
            <option value="ES">ES</option>
          </select>
          <span>{Years}</span>
        </Box>
        <Box>
          <span>
            <NavigateNextRoundedIcon
              sx={{
                color: "rgba(100,100,100)",
                transform: "rotate(-180deg)",
                marginRight: "5px",
                cursor: "pointer",
                backgroundColor: "rgba(150,150,150,0.1)",
              }}
              onClick={() => setYears(Years - 1)}
            />
            <NavigateNextRoundedIcon
              sx={{
                color: "rgba(100,100,100)",
                cursor: "pointer",
                backgroundColor: "rgba(150,150,150,0.1)",
              }}
              onClick={() => setYears(Years + 1)}
            />
          </span>
        </Box>
      </Box>
      <Box className={classess.Week}>
        <span
          onClick={() => setDays(1)}
          className={Days === 1 ? classess.Day : null}
        >
          01
        </span>
        <span
          onClick={() => setDays(2)}
          className={Days === 2 ? classess.Day : null}
        >
          02
        </span>
        <span
          onClick={() => setDays(3)}
          className={Days === 3 ? classess.Day : null}
        >
          03
        </span>
        <span
          onClick={() => setDays(4)}
          className={Days === 4 ? classess.Day : null}
        >
          04
        </span>
        <span
          onClick={() => setDays(5)}
          className={Days === 5 ? classess.Day : null}
        >
          05
        </span>
        <span
          onClick={() => setDays(6)}
          className={Days === 6 ? classess.Day : null}
        >
          06
        </span>
        <span
          onClick={() => setDays(7)}
          className={Days === 7 ? classess.Day : null}
        >
          07
        </span>
      </Box>
      <Box className={classess.Week}>
        <span
          onClick={() => setDays(8)}
          className={Days === 8 ? classess.Day : null}
        >
          08
        </span>
        <span
          onClick={() => setDays(9)}
          className={Days === 9 ? classess.Day : null}
        >
          09
        </span>
        <span
          onClick={() => setDays(10)}
          className={Days === 10 ? classess.Day : null}
        >
          10
        </span>
        <span
          onClick={() => setDays(11)}
          className={Days === 11 ? classess.Day : null}
        >
          11
        </span>
        <span
          onClick={() => setDays(12)}
          className={Days === 12 ? classess.Day : null}
        >
          12
        </span>
        <span
          onClick={() => setDays(13)}
          className={Days === 13 ? classess.Day : null}
        >
          13
        </span>
        <span
          onClick={() => setDays(14)}
          className={Days === 14 ? classess.Day : null}
        >
          14
        </span>
      </Box>
      <Box className={classess.Week}>
        <span
          onClick={() => setDays(15)}
          className={Days === 15 ? classess.Day : null}
        >
          15
        </span>
        <span
          onClick={() => setDays(16)}
          className={Days === 16 ? classess.Day : null}
        >
          16
        </span>
        <span
          onClick={() => setDays(17)}
          className={Days === 17 ? classess.Day : null}
        >
          17
        </span>
        <span
          onClick={() => setDays(18)}
          className={Days === 18 ? classess.Day : null}
        >
          18
        </span>
        <span
          onClick={() => setDays(19)}
          className={Days === 19 ? classess.Day : null}
        >
          19
        </span>
        <span
          onClick={() => setDays(20)}
          className={Days === 20 ? classess.Day : null}
        >
          20
        </span>
        <span
          onClick={() => setDays(21)}
          className={Days === 21 ? classess.Day : null}
        >
          21
        </span>
      </Box>
      <Box className={classess.Week}>
        <span
          onClick={() => setDays(22)}
          className={Days === 22 ? classess.Day : null}
        >
          22
        </span>
        <span
          onClick={() => setDays(23)}
          className={Days === 23 ? classess.Day : null}
        >
          23
        </span>
        <span
          onClick={() => setDays(24)}
          className={Days === 24 ? classess.Day : null}
        >
          24
        </span>
        <span
          onClick={() => setDays(25)}
          className={Days === 25 ? classess.Day : null}
        >
          25
        </span>
        <span
          onClick={() => setDays(26)}
          className={Days === 26 ? classess.Day : null}
        >
          26
        </span>
        <span
          onClick={() => setDays(27)}
          className={Days === 27 ? classess.Day : null}
        >
          27
        </span>
        <span
          onClick={() => setDays(28)}
          className={Days === 28 ? classess.Day : null}
        >
          28
        </span>
      </Box>
      <Box className={classess.Week}>
        <span
          onClick={() => setDays(29)}
          className={Days === 29 ? classess.Day : null}
        >
          29
        </span>
        <span
          onClick={() => setDays(30)}
          className={Days === 30 ? classess.Day : null}
        >
          30
        </span>
        <span
          onClick={() => setDays(31)}
          className={Days === 31 ? classess.Day : null}
        >
          31
        </span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
      </Box>
    </Box>
  );
};

export default Calander;
