import React, { Fragment, useEffect, useState } from "react";
import classess from "./Daily.module.css";
import { Box } from "@mui/material";
import Calander from "../../components/calander/Calander";
import Paper from "@mui/material/Paper";
import InputBase from "@mui/material/InputBase";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import DirectionsIcon from "@mui/icons-material/Directions";
import SendRoundedIcon from "@mui/icons-material/SendRounded";
import Parse from "parse";
import DailyItems from "./DailyItems";
import LoadingPage from "../../components/loading/LoadingPage";
import locales from "../../context/locales.json";
import NodataPage from "../../components/nodata/NodataPage";

const Daily = ({
  User,
  Years,
  setYears,
  Days,
  setDays,
  selectedDaily,
  setselectedDaily,
}) => {
  const [formData, setFormData] = useState("");
  const [Edited, setEdited] = useState("");
  const [Loading, setLoading] = useState(true);
  const [Result, setResult] = useState([]);
  const handleSubmit = async (e) => {
    e.preventDefault();
    Parse.initialize(
      "RAq9NED8me45klAB74MkxKCU32f4IQq65k0cTrwb",
      "QFbcAH1NgoBhSKT8M063ikxF9dGoL8PFa0mEiBIF"
    );
    Parse.serverURL = "https://parseapi.back4app.com/";
    const USER = `${User}Daily`;
    const FormSubmission = Parse.Object.extend(USER);
    if (formData.length !== 0 && Edited.length === 0) {
      const now = new Date().toLocaleTimeString();
      const formSubmission = new FormSubmission();
      formSubmission.set("USER", User);
      formSubmission.set("TARGET", formData.trim().toString());
      formSubmission.set(
        "DATE",
        Days + "-" + selectedDaily + "-" + Years + " | " + now
      );
      try {
        await formSubmission.save();
        setFormData("");
        fetchData();
      } catch (error) {
        console.error("Error while submitting form: ", error);
      }
    } else {
      const query = new Parse.Query(FormSubmission);
      const object = await query.get(Edited);
      object.set("TARGET", formData);
      try {
        await object.save();
        setFormData("");
        fetchData();
      } catch (error) {
        console.error("Error while submitting form: ", error);
      }
    }
  };
  const fetchData = async () => {
    Parse.initialize(
      "RAq9NED8me45klAB74MkxKCU32f4IQq65k0cTrwb",
      "QFbcAH1NgoBhSKT8M063ikxF9dGoL8PFa0mEiBIF"
    );
    const USER = `${User}Daily`;
    Parse.serverURL = "https://parseapi.back4app.com/";
    const query = new Parse.Query(USER);
    try {
      setLoading(false);
      const results = await query.find();
      setResult(results);
    } catch (error) {
      console.error("Error fetching objects:", error);
      alert("Error fetching objects:", error);
    }
  };
  useEffect(() => {
    fetchData();
  }, []);
  return (
    <Fragment>
      {Loading ? (
        <LoadingPage />
      ) : (
        <Box className={classess.Daily}>
          <Box className={classess.Left}>
            <Calander
              Years={Years}
              setYears={setYears}
              Days={Days}
              setDays={setDays}
              selectedDaily={selectedDaily}
              setselectedDaily={setselectedDaily}
            />
            {Result.length === 0 ? <NodataPage MT="200" /> : null}
          </Box>
          <Box className={classess.Right}>
            <Box className={classess.RightUp}>
              {Result.length === 0 ? (
                <NodataPage MT="0" />
              ) : (
                Result.map((OPT, index) =>
                  OPT.toJSON().STATUS !== "DONE" &&
                  OPT.toJSON().USER === User ? (
                    <Fragment key={index}>
                      <DailyItems
                        Target={OPT.toJSON().TARGET}
                        User={OPT.toJSON().USER}
                        Date={OPT.toJSON().DATE}
                        ObjectID={OPT.toJSON().objectId}
                        fetchData={fetchData}
                        setFormData={setFormData}
                        setEdited={setEdited}
                      />
                      <br />
                    </Fragment>
                  ) : null
                )
              )}
            </Box>
            <Box className={classess.RightDown} onSubmit={handleSubmit}>
              <Paper component="form" className={classess.Input}>
                <InputBase
                  sx={{ ml: 1, flex: 1, fontFamily: "A", fontSize: "1.2rem" }}
                  placeholder={locales.Placeholder}
                  value={formData}
                  onChange={(e) => setFormData(e.target.value)}
                />
                <IconButton
                  type="button"
                  sx={{ p: "10px" }}
                  aria-label="search"
                  onClick={handleSubmit}
                >
                  <SendRoundedIcon sx={{ color: "#4edfab" }} />
                </IconButton>
                <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
                <IconButton
                  sx={{ p: "10px", color: "red" }}
                  aria-label="directions"
                  onClick={() => setFormData("")}
                >
                  <DirectionsIcon />
                </IconButton>
              </Paper>
            </Box>
            {/* <Box className={classess.Exist}>" No data exist yet!!! "</Box> */}
          </Box>
        </Box>
      )}
      {/* <Plus setSelect={setSelect} /> */}
    </Fragment>
  );
};

export default Daily;
