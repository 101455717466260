import { Avatar, Box } from "@mui/material";
import React, { useState } from "react";
import classess from "./Login.module.css";
import logo from "../../assets/image.png";
import Ramin from "../../assets/gehlert.jpg";
import Nessa from "../../assets/nessa.jpeg";
import locales from "../../context/locales.json";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

const Login = ({ User, setUser, setLogin }) => {
  const [pass, setPass] = useState("");
  const [error, setError] = useState(false);
  const handleSubmit = (e) => {
    e.preventDefault();
    if (pass.trim().length === 0) {
      setError(true);
      setTimeout(() => setError(false), 400);
    } else {
      if (
        (User === locales.Ramin && pass === locales.RaminPass) ||
        (User === locales.Nessa && pass === locales.NessaPass)
      ) {
        setLogin(true);
      }
      if (
        (User === locales.Ramin && pass !== locales.RaminPass) ||
        (User === locales.Nessa && pass !== locales.NessaPass)
      ) {
        setError(true);
        setTimeout(() => setError(false), 400);
      }
    }
  };
  return (
    <Box className={classess.Login}>
      <Avatar
        src={Nessa}
        className={classess.Nessa}
        onClick={() => setUser(locales.Nessa)}
        sx={{ zIndex: User === locales.Nessa ? "2" : "1" }}
      />
      <Avatar
        src={Ramin}
        className={classess.Ramin}
        onClick={() => setUser(locales.Ramin)}
        sx={{ zIndex: User === locales.Ramin ? "2" : "1" }}
      />
      <form className={error ? classess.error : ""} onSubmit={handleSubmit}>
        <input
          type="password"
          value={pass}
          onChange={(e) => setPass(e.target.value)}
          style={{ border: error ? "1px solid red" : "1px solid gray" }}
        />
        <ArrowForwardIcon
          className={classess.icon}
          sx={{ color: error ? "red" : "green" }}
          onClick={handleSubmit}
        />
      </form>
      <img src={logo} alt="" className={classess.logo} />
    </Box>
  );
};

export default Login;
