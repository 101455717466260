import React from "react";
import classess from "./Header.module.css";
import { Avatar, Box } from "@mui/material";
import logo from "../../assets/image.png";
import locales from "../../context/locales.json";
import avatar1 from "../../assets/gehlert.jpg";
import avatar2 from "../../assets/nessa.jpeg";

const Header = ({ select, setSelect, User }) => {
  return (
    <Box className={classess.Header}>
      <Box className={classess.Logo}>
        <img src={logo} alt="" />
      </Box>
      <Box className={classess.Menu}>
        <span
          className={select === locales.Daily ? "AUL2" : "AUL"}
          onClick={() => setSelect(locales.Daily)}
        >
          {locales.Daily}
        </span>
        <span
          className={select === locales.Recieption ? "AUL2" : "AUL"}
          onClick={() => setSelect(locales.Recieption)}
        >
          {locales.Recieption}
        </span>
        <span
          className={select === locales.Account ? "AUL2" : "AUL"}
          onClick={() => setSelect(locales.Account)}
        >
          {locales.Account}
        </span>
        <span
          className={select === locales.Customers ? "AUL2" : "AUL"}
          onClick={() => setSelect(locales.Customers)}
        >
          {locales.Customers}
        </span>
      </Box>
      <Box className={classess.Account}>
        <Avatar
          src={User === locales.Ramin ? avatar1 : avatar2}
          className={classess.avatar}
          onClick={() => setSelect(locales.Profile)}
        />
      </Box>
    </Box>
  );
};

export default Header;
