import { Avatar, Box } from "@mui/material";
import React from "react";
import classess from "./Info.module.css";
import avatar1 from "../../assets/gehlert.jpg";
import avatar2 from "../../assets/nessa.jpeg";
import locales from "../../context/locales.json";

const Info = ({ User, setLogin }) => {
  return (
    <Box className={classess.Info}>
      <Avatar
        className={classess.avatar}
        sx={{ width: "70px", height: "70px" }}
        src={User === locales.Ramin ? avatar1 : avatar2}
      />
      <span className={classess.Title}>
        {User === locales.Ramin ? "RAMIN MAGHSOUDI" : "NESSA MAGHSOUDI"}
      </span>
      <span className={classess.User}>
        User : <label>NESSA</label>
      </span>
      <span className={classess.Email}>
        E-mail: <label>Unknown</label>
      </span>
      <span className={classess.Role}>
        Role : <label>Employee</label>
      </span>
      <span className={classess.ChangeUser} onClick={() => setLogin(false)}>
        Change user
      </span>
    </Box>
  );
};

export default Info;
