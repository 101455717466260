import { Avatar, Box } from "@mui/material";
import React, { useState } from "react";
import classess from "./Profile.module.css";
import avatar1 from "../../assets/gehlert.jpg";
import avatar2 from "../../assets/nessa.jpeg";
import locales from "../../context/locales.json";
import Info from "./Info";
import Change from "./Change";

const Profile = ({ User, setLogin }) => {
  const [type, setType] = useState(locales.ProfileInformation);
  return (
    <Box className={classess.Profile}>
      <Box className={classess.Left}>
        <Avatar
          src={User === locales.Ramin ? avatar1 : avatar2}
          // sx={{ width: "70px", height: "70px" }}
          className={classess.avatar}
        />
        <span onClick={() => setType(locales.ProfileInformation)}>
          {locales.ProfileInformation}
        </span>
        <span onClick={() => setType(locales.ProfileChangePass)}>
          {locales.ProfileChangePass}
        </span>
      </Box>
      <Box className={classess.Right}>
        {type === locales.ProfileInformation ? (
          <Info User={User} setLogin={setLogin} />
        ) : (
          <Change />
        )}
      </Box>
    </Box>
  );
};

export default Profile;
