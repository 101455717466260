import React from "react";
import classess from "./NodataPage.module.css";
import { Box } from "@mui/material";
import locales from "../../context/locales.json";

const NodataPage = ({ MT }) => {
  return (
    <Box sx={{ marginTop: `${MT}px` }} className={classess.NodataPage}>
      {locales.NoData}
    </Box>
  );
};

export default NodataPage;
