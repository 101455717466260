import { Box, Menu, MenuItem } from "@mui/material";
import React, { Fragment, useState } from "react";
import classess from "./DailyItems.module.css";
import ExpandMoreRoundedIcon from "@mui/icons-material/ExpandMoreRounded";
import Parse from "parse";

const DailyItems = ({
  Target,
  User,
  Date,
  ObjectID,
  fetchData,
  setFormData,
  setEdited,
}) => {
  const ITEM_HEIGHT = 48;

  const options = ["Done", "Change", "Edit", "Delete"];
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = async (e) => {
    // console.log(e);
    // console.log(ObjectID);
    // setAnchorEl(null);
    Parse.initialize(
      "RAq9NED8me45klAB74MkxKCU32f4IQq65k0cTrwb",
      "QFbcAH1NgoBhSKT8M063ikxF9dGoL8PFa0mEiBIF"
    );
    Parse.serverURL = "https://parseapi.back4app.com/";
    const USER = `${User}Daily`;
    const FormSubmission = Parse.Object.extend(USER);
    const query = new Parse.Query(FormSubmission);
    try {
      const object = await query.get(ObjectID);
      if (e === "Done") {
        object.set("STATUS", "DONE");
        await object.save();
        fetchData();
      }
      if (e === "Change") {
        // console.log(e);
      }
      if (e === "Edit") {
        setFormData(Target);
        setEdited(ObjectID);
        // object.set("TARGET", "TARGET2000");
        // await object.save();
        // console.log("Data updated successfully");
      }
      if (e === "Delete") {
        await object.destroy();
        fetchData();
      }

      setAnchorEl(null);
    } catch (error) {
      console.error("Error while updating data", error);
    }
  };
  return (
    <Box className={classess.DI}>
      <Box>
        {User}
        <ExpandMoreRoundedIcon
          sx={{
            marginBottom: "-8px",
            marginRight: "-8px",
            cursor: "pointer",
            color: "rgba(150,150,150,0.8)",
          }}
          aria-label="more"
          id="long-button"
          aria-controls={open ? "long-menu" : undefined}
          aria-expanded={open ? "true" : undefined}
          aria-haspopup="true"
          onClick={handleClick}
        />
        <Menu
          id="long-menu"
          MenuListProps={{
            "aria-labelledby": "long-button",
          }}
          anchorEl={anchorEl}
          open={open}
          onClose={() => setAnchorEl(null)}
          PaperProps={{
            style: {
              maxHeight: ITEM_HEIGHT * 4.5,
              width: "20ch",
            },
          }}
        >
          {options.map((option) => (
            <MenuItem
              key={option}
              selected={option === "Pyxis"}
              onClick={() => handleClose(option)}
              sx={{ fontSize: "0.8rem" }}
            >
              {option}
            </MenuItem>
          ))}
        </Menu>
      </Box>
      <Box>{Target}</Box>
      <Box>{Date}</Box>
    </Box>
  );
};

export default DailyItems;
